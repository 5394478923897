export const getUniqueListBy = (arr: any, key: string) => {
	return [...new Map(arr.map((item: any) => [item[key], item])).values()]
}

export const displayNumberWithSuffix = (number: number): string => {
	if (!number) return '-'

	if (number >= 1000000000) {
	  return (number/1000000000).toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' ร้อยล้าน'
	} 
	if (number >= 1000000) {
	  return (number/1000000).toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' ล้าน'
	} 
	if (number >= 100000) {
		return (number/100000).toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' หมื่น'
	} 
	if (number >= 10000) {
	  return (number/10000).toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' หมื่น'
	} 
	if (number >= 1000) {
		return (number/1000).toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' พัน'
	} 
	return number.toLocaleString()
}