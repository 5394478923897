
import {
	computed,
	defineComponent,
	onMounted,
	PropType,
	ref,
	watchEffect,
} from 'vue'
import {
	SubDistrictI,
	DistrictI,
	ProvinceI,
	AddressI,
} from '@/api/address/address.api'
import { getUniqueListBy } from '@/utils/common'
import { useAddressStore } from '@/pinia/address/address.pinia'

type InputDefault = string | number | null | any
export interface AddressProp {
	address: string | null
	province: InputDefault
	district: InputDefault
	subDistrict: InputDefault
	zipCode: InputDefault
}

export default defineComponent({
	name: 'AddressSystem',
	props: {
		address: {
			type: String,
			default: null,
		},
		province: {
			type: Object,
			default: null,
		},
		district: {
			type: Object,
			default: null,
		},
		subDistrict: {
			type: Object,
			default: null,
		},
		zipCode: {
			type: Number,
			default: null,
		},
		isOrderForm: {
			type: Boolean,
			default: false,
		},
		isDisabledAllForm: {
			type: Boolean,
			default: false,
		},
	},
	emits: [
		'onValidate',
		'update:address',
		'update:province',
		'update:district',
		'update:subDistrict',
		'update:zipCode',
	],
	setup(props, { emit }) {
		//
		const formSystemRef = ref<any>(null)
		const addressInputRef = ref()
		const searchAddress = ref(null)
		const isLoadingProvince = ref<boolean>(true)
		const addressStore = useAddressStore()
		const provinceList = computed<ProvinceI[]>(() => addressStore.province)
		// Computed from ProvinceList
		const districtList = computed<DistrictI[]>(() => {
			if (props.province) {
				const filterByProvinceId = addressStore.provinceList.filter(
					(pv) => pv.provinceCode === props.province?.provinceCode
				)
				return getUniqueListBy(
					filterByProvinceId,
					'districtCode'
				) as DistrictI[]
			}
			return []
		})
		const subDistrictList = computed<SubDistrictI[]>(() => {
			if (props.district && districtList.value) {
				const filterByDistrictId = addressStore.provinceList.filter(
					(sub) => sub.districtCode === props.district?.districtCode
				)
				return getUniqueListBy(
					filterByDistrictId,
					'subDistrictCode'
				) as any
			}
			return []
		})
		const zipCodeList = computed(() => {
			if (subDistrictList?.value?.length && props.subDistrict) {
				const findZipCodeListFromSubDistrictCode =
					addressStore.provinceList.find((addr) => {
						return (
							addr.provinceCode === props.province?.provinceCode &&
							addr.districtCode === props.district?.districtCode &&
							addr.subDistrictCode === props.subDistrict?.subDistrictCode
						)
					})
				return findZipCodeListFromSubDistrictCode?.zipCode
			}
			return []
		})

		const onValidate = (
			validateKey: string,
			isValid: boolean,
			message: string
		) => {
			emit('onValidate', { validateKey, isValid, message })
		}

		const validateAll = async () => {
			const isValid = await formSystemRef.value?.validate()
			return isValid
		}

		const validateDescription = (_: any, value: string, callback: any) => {
			const removeAllSpace = value?.replaceAll(' ', '')
			if (!removeAllSpace) {
				return callback(new Error('กรุณากรอกรายละเอียดที่อยู่'))
			}
			callback()
			return
		}

		const handleSearchAddress = (queryString: string, cb: any) => {
			if (!queryString) return cb([])

			const findAddressByZipCode = addressStore.provinceList.filter(
				(addr) => addr.zipCode.includes(Number(queryString))
			)
			if (!findAddressByZipCode?.length) return cb([])

			const extractDupplicateZipCode = []

			for (const addr of findAddressByZipCode) {
				if (addr.zipCode.length > 1) {
					for (const zipCode of addr.zipCode) {
						extractDupplicateZipCode.push({
							...addr,
							zipCode: [zipCode],
						})
					}
				} else {
					extractDupplicateZipCode.push(addr)
				}
			}

			cb(extractDupplicateZipCode)
		}

		const handleSelectAddress = (addressItem: AddressI) => {
			// emit('update:address', `ตำบล ${addressItem.districts.subDistricts.name} อำเภอ ${addressItem.districts.name} จังหวัด ${addressItem.name} ${addressItem.districts.subDistricts.zipCode}`) // Update Address
			// Emit Province, District, SubDistrict, ZipCode
			emit('update:province', { provinceName: addressItem.provinceName, provinceCode: addressItem.provinceCode })
			emit('update:district', { districtName: addressItem.districtName, districtCode: addressItem.districtCode })
			emit('update:subDistrict', { subDistrictName: addressItem.subDistrictName, subDistrictCode: addressItem.subDistrictCode })
			emit('update:zipCode', addressItem.zipCode?.[0] as any)

			// Focus address
			// addressInputRef.value.focus()
			// const element = document.getElementById('addressInput')
			// element?.scrollIntoView({ behavior: 'smooth' })
		}

		const onProvinceChange = (value: number) => {
			emit('update:province', value)
			// clear district, subDis, zipCode
			emit('update:district', null)
			emit('update:subDistrict', null)
			emit('update:zipCode', null)
		}

		const onDistrictChange = (value: number) => {
			emit('update:district', value)
			// clear, subDis, zipCode
			emit('update:subDistrict', null)
			emit('update:zipCode', null)
		}

		const onSubDistrictChange = (value: number) => {
			emit('update:subDistrict', value)
			// Clear Zipcode
			// emit('update:zipCode', zipCodeList.value[0])
		}

		watchEffect(() => {
			if (props.subDistrict) {
				if (props.zipCode) {
					const filterZipcode = zipCodeList.value?.find(
						(zipCode) => zipCode === props.zipCode
					)
					emit('update:zipCode', filterZipcode)
				} else {
					emit('update:zipCode', (zipCodeList.value as any)?.[0])
				}
			}
		})

		onMounted(() => {
			isLoadingProvince.value = true
			addressStore.getAllProvinceStore().finally(() => {
				isLoadingProvince.value = false
			})
		})

		return {
			onValidate,
			formSystemRef,
			searchAddress,
			validateAll,
			handleSearchAddress,
			handleSelectAddress,
			provinceList,
			districtList,
			subDistrictList,
			zipCodeList,
			onProvinceChange,
			onDistrictChange,
			onSubDistrictChange,
			isLoadingProvince,
			addressInputRef,
			validateDescription,
		}
	},
})
