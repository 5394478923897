import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f51027e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "item" }
const _hoisted_3 = { class: "item" }
const _hoisted_4 = { class: "item" }
const _hoisted_5 = { class: "item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (!_ctx.isLoadingProvince)
    ? (_openBlock(), _createBlock(_component_el_form, {
        key: 0,
        "label-position": "top",
        model: {
			address: _ctx.address,
			province: _ctx.province,
			district: _ctx.district,
			subDistrict: _ctx.subDistrict,
			zipCode: _ctx.zipCode,
		},
        "scroll-to-error": true,
        onValidate: _ctx.onValidate,
        ref: "formSystemRef"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            (!_ctx.isOrderForm)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  class: "custom-text",
                  label: "ค้นหาที่อยู่ด้วย รหัสไปรษณีย์ (ระบุแค่รหัสไปรษณีย์)"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_autocomplete, {
                      placeholder: "เช่น 10900",
                      disabled: _ctx.isDisabledAllForm,
                      modelValue: _ctx.searchAddress,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchAddress) = $event)),
                      "prefix-icon": "el-icon-search",
                      "fetch-suggestions": _ctx.handleSearchAddress,
                      onSelect: _ctx.handleSelectAddress,
                      "popper-class": "address-autocomplete"
                    }, {
                      default: _withCtx(({ item }) => [
                        _createElementVNode("div", _hoisted_1, [
                          _createElementVNode("span", _hoisted_2, _toDisplayString(item.provinceName), 1),
                          _createElementVNode("span", _hoisted_3, _toDisplayString(item.districtName), 1),
                          _createElementVNode("span", _hoisted_4, _toDisplayString(item.subDistrictName), 1),
                          _createElementVNode("span", _hoisted_5, _toDisplayString(item.zipCode.join(', ')), 1)
                        ])
                      ]),
                      _: 1
                    }, 8, ["disabled", "modelValue", "fetch-suggestions", "onSelect"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_el_row, { gutter: 20 }, {
              default: _withCtx(() => [
                (_ctx.isOrderForm)
                  ? (_openBlock(), _createBlock(_component_el_col, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "รายละเอียดที่อยู่ (บ้านเลขที่, หมู่บ้าน, อาคาร, ถนน, ซอย)",
                          prop: "address",
                          rules: [
							// { required: true, message: 'กรุณากรอกรายละเอียดที่อยู่', trigger: ['change', 'blur'] }
							{
								validator: _ctx.validateDescription,
								trigger: ['change', 'blur'],
							},
						]
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              disabled: _ctx.isDisabledAllForm,
                              id: "addressInput",
                              ref: "addressInputRef",
                              type: "textarea",
                              rows: 5,
                              placeholder: "รายละเอียดที่อยู่",
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = 
								(value) => _ctx.$emit('update:address', value)
							),
                              modelValue: _ctx.address
                            }, null, 8, ["disabled", "modelValue"])
                          ]),
                          _: 1
                        }, 8, ["rules"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "จังหวัด",
                      prop: "province",
                      rules: [
							{
								required: true,
								message: 'กรุณาเลือกจังหวัด',
								trigger: ['change', 'blur'],
							},
						]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          disabled: _ctx.isDisabledAllForm,
                          onChange: _ctx.onProvinceChange,
                          modelValue: _ctx.province,
                          filterable: "",
                          placeholder: "จังหวัด",
                          "value-key": "provinceCode"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.provinceList, (province) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: `province-${province.provinceCode}`,
                                label: province.provinceName,
                                value: province
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["disabled", "onChange", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "เขต/อําเภอ",
                      prop: "district",
                      rules: [
							{
								required: true,
								message: 'กรุณาระบุ เขต/อําเภอ',
								trigger: ['change', 'blur'],
							},
						]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          disabled: _ctx.isDisabledAllForm,
                          onChange: _ctx.onDistrictChange,
                          modelValue: _ctx.district,
                          filterable: "",
                          placeholder: "เขต/อําเภอ",
                          "value-key": "districtCode"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.districtList && _ctx.districtList, (district) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: `district-${district.districtCode}`,
                                label: district.districtName,
                                value: district
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["disabled", "onChange", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, { gutter: 20 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "แขวง/ตำบล",
                      prop: "subDistrict",
                      rules: [
							{
								required: true,
								message: 'กรุณาระบุ แขวง/ตำบล',
								trigger: ['change', 'blur'],
							},
						]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          disabled: _ctx.isDisabledAllForm,
                          onChange: _ctx.onSubDistrictChange,
                          modelValue: _ctx.subDistrict,
                          filterable: "",
                          placeholder: "แขวง/ตำบล",
                          "value-key": "subDistrictCode"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subDistrictList, (subDis) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: `subDis-${subDis.subDistrictCode}`,
                                label: subDis.subDistrictName,
                                value: subDis
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["disabled", "onChange", "modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "รหัสไปรษณีย์",
                      prop: "zipCode",
                      rules: [
							{
								required: true,
								message: 'กรุณาระบุรหัสไปรษณีย์',
								trigger: ['change', 'blur'],
							},
						]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          onChange: _cache[2] || (_cache[2] = (value) => _ctx.$emit('update:zipCode', value)),
                          modelValue: _ctx.zipCode,
                          filterable: "",
                          disabled: _ctx.isDisabledAllForm,
                          placeholder: "รหัสไปรษณีย์"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.zipCodeList, (zip) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: `zip-${zip}`,
                                label: `${zip}`,
                                value: zip
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ], true)
        ]),
        _: 3
      }, 8, ["model", "onValidate"]))
    : (_openBlock(), _createBlock(_component_el_skeleton, {
        key: 1,
        rows: 10,
        animated: ""
      }))
}